// modules/contato.js
import axios from 'axios';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

export default function contatoForm() {
    const showToastify = (message, opts) => {
        Toastify({
            duration: 3000,
            position: "center",
            style: { background: "rgba(193, 255, 129)", fontSize: "18px", color: "rgba(45, 71, 120, 1)" },
            text: message,
            ...opts
        }).showToast();
    };

    const formContato = document.querySelector('#wf-form-Formul-rio-VVR-Group');
    const successMessage = document.querySelector('#success-message');
    const errorMessage = document.querySelector('#error-message');

    formContato?.addEventListener("submit", (e) => {
        e.preventDefault();
        disabledBtn();

        const form = new FormData(e.target);
        const form_data = {
            nome: form.get('Campo-de-nome'),
            email: form.get('Campo-de-e-mail'),
            mensagem: form.get('Campo-de-Mensagem')
        };

        const url = "/fale-conosco/";
        axios.post(url, form_data)
            .then((response) => showMessage(response))
            .catch((error) => showMessage(error.response));
    });

    function showMessage(response) {
        const isSuccess = response?.status === 200;

        // Mostrar/ocultar as mensagens de sucesso e erro
        if (isSuccess) {
            successMessage.style.display = 'block';
            errorMessage.style.display = 'none';
            showToastify('#success-message', {
            });
            formContato.reset();
        } else {
            successMessage.style.display = 'none';
            errorMessage.style.display = 'block';
            showToastify('#error-message', {
            });
        }

        setTimeout(enabledBtn, 1000);
    }

    function disabledBtn() {
        const btnSubmit = formContato.querySelector('.submit-button');
        btnSubmit.disabled = true;
        btnSubmit.textContent = 'Enviando...'; 
        btnSubmit.classList.add("disabled");
    }

    function enabledBtn() {
        const btnSubmit = formContato.querySelector('.submit-button');
        btnSubmit.disabled = false;
        btnSubmit.textContent = 'Enviar';
        btnSubmit.classList.remove("disabled");
    }
}
